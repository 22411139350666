/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Import library functions for theme creation.
@import '~@angular/material/theming';

@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

// Include non-theme styles for core.
@include mat-core();

// Define your application's custom theme.
$primary: mat-palette($mat-indigo);
$accent:  mat-palette($mat-pink, A200, A100, A400);
$theme: mat-light-theme($primary, $accent);

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin candy-carousel-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Define any styles affected by the theme.
  .candy-carousel {
    // Use mat-color to extract individual colors from a palette.
    background-color: mat-color($primary);
    border-color: mat-color($accent, A400);
  }
}

@mixin candy-carousel-typography($config) {
  .candy-carousel {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}

// Include theme styles for Angular Material components.
@include angular-material-theme($theme);

// Include theme styles for your custom components.
@include candy-carousel-theme($theme);

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

@import "~ng-pick-datetime/assets/style/picker.min.css";

@import url(//db.onlinewebfonts.com/c/56782aeaf933dac6e6602fb87160cfb6?family=Dolce);
@font-face {font-family: "Dolce"; src: url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.eot");
  src: url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/56782aeaf933dac6e6602fb87160cfb6.svg#Dolce") format("svg"); }

.owl-dt-timer-content .owl-dt-timer-input {
  width: 2.5em;
}

div[stripbar] {
  background-image: url('./assets/images/topheader-line.svg');
  height: 5px;
}

ion-icon[toolbar] {
  margin-left: 15px;
}

ion-icon[green] {
  color: var(--theme-color-primary2);
}

ion-icon[red] {
  color: red;
}

ion-icon[toolbar2] {
  margin-right: 15px;
}

ion-text[toolbar] {
  color: var(--theme-color-primary2);
}

ion-label[primary1] {
  color: var(--theme-color-primary1);
}

ion-label[primary2] {
  color: var(--theme-color-primary2);
}

ion-label[primary3] {
  color: var(--theme-color-primary3);
}


ion-label[input] {
  font-size: 13px;
  color: var(--theme-color-action2);
}

ion-label[gray] {
  color: var(--theme-color-action2);
}

ion-input[box] {
  border-color: var(--theme-color-secondary4);
}

ion-button[green] {
  --background: var(--theme-color-primary2);
}

ion-button[grey] {
  color: grey;
  font-weight: bold;
}

ion-text[green] {
  color: var(--theme-color-primary2);
}

ion-text[red] {
  color: #A22531;
}

ion-text[grey] {
  color: var(--theme-color-action2);
}

ion-text[close] {
  color: red;
  font-size: 10px;
  padding-right: 5px;
}

ion-text[black] {
  color: black;
}

ion-button[transparent] {
  --color: var(--theme-color-primary2);
}

ion-col[center] {
  text-align: center;
}

ion-col[flexCenter] {
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-col[flexRight] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

ion-col[flexLeft] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

ion-col[flexTopRight] {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

ion-modal.ticket-details {
  padding-top: 10%;
  background: rgb(76,76,76);
}

ion-modal.auto-height {
  &.bottom {
      align-items: flex-end;
  }

  padding-top: 80%;
  --border-radius: 6px 6px 0px 0px;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }
}

@media(max-height: 460px) {
  ion-modal.check-in {
    padding-top: 125%;
  }

  ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 40%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }
}

@media(min-width: 320px) {
  ion-modal.check-in {
    padding-top: 125%;
    --border-radius: 6px 6px 0px 0px;
  }

  ion-modal.email-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 100%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }

  ion-modal.succeed-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 80%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }
}

@media(min-width: 360px) {
  ion-modal.check-in {
    padding-top: 125%;
    --border-radius: 6px 6px 0px 0px;
  }

  ion-modal.email-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 110%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }  

  ion-modal.succeed-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 100%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }
}

ion-modal.auto-height-ticket {
  &.bottom {
      align-items: flex-end;
  }

  --height: auto;
  padding-right: 15pt;
  padding-left: 15pt;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }

  ion-modal.succeed-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 100%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }
}

@media(min-width: 390px) {
  ion-modal.check-in {
    padding-top: 125%;
    --border-radius: 6px 6px 0px 0px;
  }

  ion-modal.email-cert {
    &.bottom {
        align-items: flex-end;
    }
  
    padding-top: 120%;
    --border-radius: 6px 6px 0px 0px;
  
    .ion-page {
        position: relative;
        display: block;
        contain: content;
  
        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
  }
}

ion-modal.auto-height-ticket {
  &.bottom {
      align-items: flex-end;
  }

  --height: auto;
  padding-right: 15pt;
  padding-left: 15pt;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }
}

.green-text {
  color: var(--theme-color-primary2) !important;
}

.red-text {
  color: var(--theme-color-action3) !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
  justify-content: center;
  padding-top: 4px;
  font-size: 16px;
}

.action-sheet-group.sc-ion-action-sheet-md:first-child {
  padding-top: 12px;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: center;
  font-size: 16px;
}

.action-sheet-title.sc-ion-action-sheet-md {
  color: black;
  font-weight: 600;
  padding-top: 1px;
}

.action-sheet-title.sc-ion-action-sheet-ios {
  text-align: left;
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.action-sheet-sub-title.sc-ion-action-sheet-md {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  padding-top: 10px;
  font-weight: 400;
  color: var(--theme-color-secondary4);
}

.action-sheet-sub-title.sc-ion-action-sheet-ios {
  text-align: center;
  font-size: 14px;
  color: var(--theme-color-secondary4);
  font-weight: 400;
}

.action-sheet-group.sc-ion-action-sheet-md {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.action-sheet-group.sc-ion-action-sheet-ios {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

// .modal-wrapper.sc-ion-modal-md {
//   height: 100%;
// }
@media(min-width: 320px) and (max-height: 460px) {
  ion-modal.forget {
    &.bottom {
      align-items: flex-end;
    }
  
    padding-top: 70%;
    --border-radius: 6px 6px 0px 0px;
  }
}

ion-modal.forget {
  &.bottom {
    align-items: flex-end;
  }

  padding-top: 100%;
  --border-radius: 6px 6px 0px 0px;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
    }
  }
}

@media(min-width: 360px) {
  ion-modal.ticket-succeed {
    padding-top: 60%;
    --border-radius: 6px 6px 0px 0px;
  }
}


@media(min-width: 320px) {
  ion-modal.ticket-succeed {
    padding-top: 60%;
    --border-radius: 6px 6px 0px 0px;
  }
}

@media(max-height: 460px) {
  ion-modal.ticket-succeed {
    padding-top: 10%;
    --border-radius: 6px 6px 0px 0px;
  
  }
}


.action {

  border-radius: 25px;

  .home {
    margin-top: 40px;
    margin-bottom: 40px;
    color: var(--theme-color-primary2);
  }

  @media(min-width: 320px) {
    .cancel {
      text-align: center;
      color: var(--theme-color-primary2);
      margin-bottom: 10px;
      font-size: 14px;
    }

    .ok {
      text-align: center;
      margin-top: 10px;
      color: white;
      height: 10px;
      background: var(--theme-color-secondary2);
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px;
      width: -webkit-fill-available;
    }

    .ok2 {
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      color: white;
      height: 25px;
      background: var(--theme-color-primary2);
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      width: -webkit-fill-available;
    }
  }

  @media(min-width: 360px) {
    .cancel {
      text-align: center;
      color: var(--theme-color-primary2);
      margin-bottom: 40px;
    }

    .ok {
      text-align: center;
      margin-top: 20px;
      color: white;
      height: 40px;
      background: var(--theme-color-secondary2);
      margin-left: 20px;
      margin-right: 20px;
      width: -webkit-fill-available;
    }

    .ok2 {
      text-align: center;
      margin-top: 30px;
      color: white;
      height: 40px;
      background: var(--theme-color-primary2);
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      width: -webkit-fill-available;
    }
  }

  @media(min-width: 390px) {
    .cancel {
      text-align: center;
      color: var(--theme-color-primary2);
      margin-bottom: 40px;
    }

    .ok {
      text-align: center;
      margin-top: 40px;
      color: white;
      height: 40px;
      background: var(--theme-color-secondary2);
      margin-left: 20px;
      margin-right: 20px;
      width: -webkit-fill-available;
    }

    .ok2 {
      text-align: center;
      margin-top: 60px;
      color: white;
      height: 40px;
      background: var(--theme-color-primary2);
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      width: -webkit-fill-available;
    }
  }

  @media(min-width: 320px) and (max-height: 460px) {
    .cancel {
      text-align: center;
      color: var(--theme-color-primary2);
      margin-bottom: 10px;
      font-size: 12px;
    }

    .ok {
      text-align: center;
      margin-top: 55px;
      color: white;
      height: 30px;
      background: var(--theme-color-secondary2);
      margin-left: 10px;
      margin-right: 10px;
      font-size: 12px;
      width: -webkit-fill-available;
    }

    .ok2 {
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      color: white;
      height: 25px;
      background: var(--theme-color-primary2);
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      width: -webkit-fill-available;
    }
  }


    // .action-sheet-button-inner.sc-ion-action-sheet-md {
    //   justify-content: center;
    //   background: var(--theme-color-secondary2);
    // }

    // .action-sheet-button-inner.sc-ion-action-sheet-ios {
    //   justify-content: center;
    //   background: var(--theme-color-secondary2);
    // }

  .camera {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--theme-color-primary2);
  }
}

div[center] {
  text-align: center;
}

div[left] {
  text-align: left;
}

input {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

ion-searchbar {
  input {
    color: var(--theme-color-primary2) !important;
  }
}

ion-toast.toastMessage {
  text-align: center;
  color: var(--theme-color-primary2);
  --background: white
}

.aiu-footer{
  flex: 1;
  background-color: #1E285B;
  color: white;
  text-align: center;
  padding: 10px 0 10px 0;
  font-size: 12px;
}

ion-col[noMargin] {
  padding: 0;
  margin: 0;
}

ion-label[black] {
  color: black;
}